<template>
  <v-container fluid id="ImovelConsulta" class="pa-0">
    <!-- Cabeçalho do Conteudo -->
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >

      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-2 ml-4">
        Consulta de Imovel
      </v-toolbar-title>

      <!-- Botões - Filtro / Novo / Opções -->
      <v-toolbar-title class="mt-0 mb-6 mr-4 ml-4 pa-0" dark elevation="0">
        <div class="d-flex" tabindex="-1">
          <v-container
            class="pa-0 ma-0 mb-7 d-flex"
            style="margin-top: -4px;  max-width: 1200px"
          >
            <!-- Input Filtro - Chips / Formulario -->
            <v-menu
              id="vMenu"
              ref="vMenu"
              v-model="menu"
              bottom
              right
              fluid
              offset-y
              origin="top"
              class="pa-0 d-flex flex-wrap"
              elevation="0"
              :close-on-content-click="false"
              @input="abriuConsulta"
            >
              <template v-slot:activator="{ on }">
                <!-- Input do Filtro -->
                <div
                  ref="vMenuDiv"
                  class="false-filter d-flex align-center"
                  align="center"
                  v-on="on"
                >
                  <v-icon class="false-icon ml-3 mr-2" color="#FFF">
                    mdi-magnify
                  </v-icon>

                  <!-- Div de todos os chips selecionados -->
                  <div class="false-text flex-wrap">
                    <!-- Chip Codigo da Imovel -->
                    <v-chip
                      v-if="store_Imovel.filtro.cod_imovel"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Codigo:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Imovel.filtro.cod_imovel
                      }}</strong>
                    </v-chip>
                    <!-- Chip Nome da Imovel -->
                    <v-chip
                      v-if="store_Imovel.filtro.nome_imovel"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Nome do Imovel:&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Imovel.filtro.nome_imovel
                      }}</strong>
                    </v-chip>

                    <!-- Chip Situação -->
                    <v-chip
                      v-if="store_Imovel.filtro.cod_situacao"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Situação:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Imovel.filtro.situacao.situacao_nome
                      }}</strong>
                    </v-chip>
                    
                    <!-- Chip Tipo -->
                    <v-chip
                      v-if="store_Imovel.filtro.cod_imoveltipo"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Tipo:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Imovel.filtro.tipo.nome
                      }}</strong>
                    </v-chip>

                    <!-- Chip Empreendimento -->
                    <v-chip
                      v-if="store_Imovel.filtro.cod_empreendimento"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Empreendimento:&nbsp;
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Imovel.filtro.empreendimento.empreend_nome
                      }}</strong>
                    </v-chip>

                    <!-- Chip Matrícula -->
                    <v-chip
                      v-if="store_Imovel.filtro.matricula"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Matrícula:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.matricula }}</strong>
                    </v-chip>

                    <!-- Chip Cartório -->
                    <v-chip
                      v-if="store_Imovel.filtro.cartorio"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Cartório:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.cartorio }}</strong>
                    </v-chip>

                    <!-- Chip Área Construída Mínima -->
                    <v-chip
                      v-if="store_Imovel.filtro.area_construida_min"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Área Construída Min:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.area_construida_min }} m²</strong>
                    </v-chip>

                    <!-- Chip Área Construída Máxima -->
                    <v-chip
                      v-if="store_Imovel.filtro.area_construida_max"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Área Construída Max:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.area_construida_max }} m²</strong>
                    </v-chip>

                    <!-- Chip Área de Terreno Mínima -->
                    <v-chip
                      v-if="store_Imovel.filtro.area_metragem_min"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Área Terreno Min:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.area_metragem_min }} m²</strong>
                    </v-chip>

                    <!-- Chip Área de Terreno Máxima -->
                    <v-chip
                      v-if="store_Imovel.filtro.area_metragem_max"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Área Terreno Max:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.area_metragem_max }} m²</strong>
                    </v-chip>

                    <!-- Chip Quadra -->
                    <v-chip
                      v-if="store_Imovel.filtro.quadra"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Quadra:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.quadra }}</strong>
                    </v-chip>

                    <!-- Chip Lote -->
                    <v-chip
                      v-if="store_Imovel.filtro.lote"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Lote:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.lote }}</strong>
                    </v-chip>

                    <!-- Chip Fase -->
                    <v-chip
                      v-if="store_Imovel.filtro.fase"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Fase:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.fase }}</strong>
                    </v-chip>

                    <!-- Chip Endereço
                    <v-chip
                      v-if="store_Imovel.filtro.endereco"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Endereço:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.endereco }}</strong>
                    </v-chip> -->

                    <!-- Chip Data de Matrícula -->
                    <v-chip
                      v-if="store_Imovel.filtro.dt_matricula"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">Data Matrícula:&nbsp;</span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{ store_Imovel.filtro.dt_matricula }}</strong>
                    </v-chip>
                  </div>
                  <v-icon class="false-icon ml-2 mr-4" color="#FFF">
                    mdi-filter-variant-plus
                  </v-icon>
                </div>
              </template>
              <!-- Formulario do Filtro -->
              <v-container
              
                :style="{ 'background-color': COR_SECUNDARIA_2 }"
                style="
                  min-width: 800px !important;
                  max-width: 1200px !important;
                  margin-bottom: -34px;
                "
              >
                <!-- 1ª Linha do Formulario -->
                <v-row no-gutters class="linhas mb-n2">
                  <v-col md="2">
                    <!-- Input Código -->
                    <v-text-field
                      id="txtCodigos"
                      ref="txtCodigo"
                      dense
                      v-model="filtro_local.cod_imovel" 
                      cache-items
                      flat
                      label="Código"
                      placeholder="Código"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="100"
                      clearable
                      tabindex="0"
                      class="mr-2"
                      autofocus
                    >
                    </v-text-field>
                  </v-col>

                  <!-- Nome Imovel -->
                  <v-col md="7">
                    <v-text-field
                      v-model="filtro_local.nome_imovel"
                      item-text="nome_imovel"
                      item-value="nome_imovel"
                      label="Nome do Imovel"
                      placeholder="Nome do Imovel"
                      outlined
                      filled
                      background-color="#FFF"
                      maxlength="60"
                      dense
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>

                 <!-- Tipo -->
                  <v-col md="3">
                    <v-autocomplete
                      v-model="filtro_local.tipo"
                      :items="arrayTipo"
                      label="Tipo"
                      item-text="nome"
                      item-value="cod_imoveltipo"
                      placeholder="Tipo"
                      outlined
                      filled
                      background-color="#fff"
                      dense
                      clearable
                      return-object
                    ></v-autocomplete>
                  </v-col>

                </v-row>
                
                <!-- 2ª Linha do Formulario -->
                <v-row no-gutters class="linhas mb-n2">
                <!-- Empreendimento -->
                  <v-col md="5">
                    <v-autocomplete 
                        v-model="filtro_local.empreendimento" 
                        :items="empreendimentos"  
                        item-text="empreend_nome" 
                        item-value="cod_empreendimento" 
                        label="Empreendimento" 
                        placeholder="Empreendimento" 
                        outlined 
                        filled 
                        background-color="#FFF" 
                        dense 
                        required 
                        flat 
                        clearable 
                        tabindex="0" 
                        return-object
                        class="mr-2">
                    </v-autocomplete>
                  </v-col>           
                   
                  <!-- Matricula -->
                  <v-col md="4">
                    <v-text-field
                      v-model="filtro_local.matricula"
                      class="mr-2"
                      loader-height="1"
                      label="Nº Matricula"
                      placeholder="Nº Matricula"
                      background-color="#FFF"
                      maxlength="15"
                      light
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Data -->
                  <v-col md="3" class="pt-0">
                    <DatePicker
                      v-model="filtro_local.dt_matricula"
                      loader-height="1"
                      background-color="#fff"
                      label="Data Matricula"
                      placeholder="Data Matricula"
                      light
                      maxlength="80"
                      filled
                      outlined
                      dense
                    ></DatePicker>
                   </v-col>
                </v-row>

                <!-- 3ª Linha do Filtro -->
                <v-row no-gutters class="linhas mb-n2">
                   <!-- Proprietario
                  <v-col md="5">
                    <v-text-field
                      v-model="filtro_local.empreend_nome"
                      class="mr-2"
                      loader-height="1"
                      label="Proprietário"
                      placeholder="Proprietário"
                      background-color="#FFF"
                      maxlength="60"
                      light
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col> -->
                  <!-- Situação -->
                  <v-col md="3">
                    <v-autocomplete
                      label="Situação"
                      v-model="filtro_local.situacao"
                      :items="arraySituacao"
                      item-text="situacao_nome"
                      item-value="cod_situacao"
                      placeholder="Situação"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      clearable
                      return-object
                      class="mr-2"
                    ></v-autocomplete>
                  </v-col>

                  <!-- Endereço
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.endereco"
                      class="mr-2"
                      loader-height="1"
                      label="Endereço"
                      placeholder="Endereço"
                      background-color="#FFF"
                      maxlength="60"
                      light
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col> -->

                  <!-- Quadra -->
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.quadra"
                      class="mr-2"
                      loader-height="1"
                      label="Quadra"
                      placeholder="Quadra"
                      background-color="#FFF"
                      maxlength="60"
                      light
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Lote -->
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.lote"
                      class="mr-2"
                      loader-height="1"
                      label="Lote"
                      placeholder="Lote"
                      background-color="#FFF"
                      maxlength="60"
                      light
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Fase -->
                  <v-col md="3">
                    <v-text-field
                      v-model="filtro_local.fase"
                      class="mr-2"
                      loader-height="1"
                      label="Fase"
                      placeholder="Fase"
                      background-color="#FFF"
                      maxlength="60"
                      light
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>

                  <!-- Cartorio
                  <v-col md="4">
                    <v-text-field
                      v-model="filtro_local.cartorio"
                      loader-height="1"
                      label="Cartorio"
                      placeholder="Cartorio"
                      background-color="#FFF"
                      maxlength="60"
                      light
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col> -->
                </v-row>

                <!-- 4ª Linha do Filtro -->
                <v-row no-gutters class="linhas">
                  <!-- Área contruida_min -->
                  <v-col md="3">
                    <VuetifyMoney
                      id="txtAreaMin."
                      ref="txtAreaMin."
                      dense
                      v-model="filtro_local.area_construida_min"
                      cache-items
                      flat
                      label="Área Construida Minima"
                      placeholder="Área Construida Minima"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      class="mr-2"
                      :options="{
                        locale: 'pt-BR',
                        prefix: '',
                        suffix: '',
                        length: 11,
                        precision: 2,
                      }"
                    >
                    </VuetifyMoney>
                  </v-col>

                  <!-- Área contruida_max -->
                  <v-col md="3">
                    <VuetifyMoney
                      id="txtAreaMax."
                      ref="txtAreaMax."
                      dense
                      v-model="filtro_local.area_construida_max"
                      cache-items
                      flat
                      label="Área Construida Maxima"
                      placeholder="Área Construida Maxima"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      class="mr-2"
                      :options="{
                        locale: 'pt-BR',
                        prefix: '',
                        suffix: '',
                        length: 11,
                        precision: 2,
                      }"
                    >
                    </VuetifyMoney>
                  </v-col>

                  <!-- Área terreno_min -->
                  <v-col md="3">
                    <VuetifyMoney
                      id="txtAreaMin."
                      ref="txtAreaMin."
                      dense
                      v-model="filtro_local.area_metragem_min"
                      cache-items
                      flat
                      label="Área Terreno Minima"
                      placeholder="Área Terreno Minima"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      class="mr-2"
                      :options="{
                        locale: 'pt-BR',
                        prefix: '',
                        suffix: '',
                        length: 11,
                        precision: 2,
                      }"
                    >
                    </VuetifyMoney>
                  </v-col>

                  <!-- Área terreno_max -->
                  <v-col md="3">
                    <VuetifyMoney
                      id="txtAreaMax."
                      ref="txtAreaMax."
                      dense
                      v-model="filtro_local.area_metragem_max"
                      cache-items
                      flat
                      label="Área Terreno Maxima"
                      placeholder="Área Terreno Maxima"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      :options="{
                        locale: 'pt-BR',
                        prefix: '',
                        suffix: '',
                        length: 11,
                        precision: 2,
                      }"
                    >
                    </VuetifyMoney>
                  </v-col>
                </v-row>

                <!-- 5ª Linha do Filtro
                <v-row no-gutters class="linhas">
                  <v-col md="12">
                    <v-combobox
                      background-color="map-deep-merge white"
                      v-model="filtro_local.caracterisica_esp"
                      :items="arrayCaracEspecial"
                      item-value="caractesristica_esp"
                      item-text="caractesristica_esp"
                      multiple
                      chips
                      label="Caracteristicas Especiais"
                      filled
                      outlined
                      dense
                    >
                      <template v-slot:selection="{item}">
                        <v-chip class="ma-1" small :style="{ color: COR_PRINCIPAL }" style="opacity: 0.7">
                          {{ item }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row> -->
              </v-container>

              <!-- Cria uma linha de divisoria -->
              <v-divider></v-divider>

              <!-- Container Botões - Limpar / Consultar -->
              <div
                class="pa-4 d-flex justify-space-between"
                v-bind:style="{ 'background-color': COR_SECUNDARIA }"
              >
                <!-- Botão Limpar Todos -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  :small="isMobile"
                  @click="limpa_todos()"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-close
                  </v-icon>
                  <span>Limpar Todos</span>
                </v-btn>

                <!-- Botao Consultar -->
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  tabindex="-1"
                  :small="isMobile"
                  @click="busca(1)"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2">
                    mdi-magnify
                  </v-icon>
                  <span>Consultar</span>
                </v-btn>
              </div>
            </v-menu>
          </v-container>

          <!-- Espaçamento entre os elementos -->
          <v-spacer></v-spacer>

          <!-- Botão Novo --------------------------------->
          <router-link
            :to="{ name: 'ImovelContainer' }"
            class="nav-link"
            aria-current="page"
            tabindex="-1"
          >
            <v-btn
              class="text-white text-capitalize"
              :color="COR_PRINCIPAL"
              elevation="0"
              :small="isMobile"
              @click="store_Imovel.acao = 'I';"
            >
              <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
              <span>Novo</span>
            </v-btn>
          </router-link>
        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <!-- Tabela -->
    <v-row class="mt-n11 mx-0">
      <!-- Grade  -->
      <v-col cols="12" class="pa-0 mx-0">
        <v-container
          class="container-principal container-rounded rounded-lg mx-0 px-0"
        >
          <!-- Conteiner -------------------------------------- -->
          <v-data-table
            id="virtualScrollTable"
            ref="virtualScrollTable"
            :items="store_Imovel.dados" 
            :headers="headers"
            :loading="loading"
            :height="tableHeight"
            :items-per-page="20"
            :hide-default-footer="true"
            :hide-default-header="isMobile"
            :page="currentPage || 1"
            :server-items-length="store_Imovel.count_dados"
            fixed-header
            dense
            style="white-space: nowrap"
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
            class="data-table"
          >
            <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
            <template #item="{ item }">
              <tr v-if="!isMobile">
                <!-- Botão de Ações -->
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                        style="width: 2%"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <!-- Lista de Ações -->
                    <v-list class="py-0">
                      <v-list-item
                        v-for="(opcao, i) in items"
                        :key="i"
                        @click="() => {}"
                        class="px-2"
                      >
                        <!-- Ação de Editar -->
                        <v-list-item-title v-if="opcao.title == 'Editar'">
                          <router-link
                            :to="'/imovelcontainer/' + item.cod_imovel"
                            class="nav-link d-flex justifi-center align-center mt-2 ml-1"
                            aria-current="page"
                            style="white-space: normal"
                          >
                            <v-icon color="green" class="mr-2 icon-menu">
                              mdi-pencil
                            </v-icon>
                            {{ opcao.title }}
                          </router-link>
                        </v-list-item-title>

                        <!-- Ação de Excluir -->
                        <v-list-item-title
                          v-else-if="opcao.title == 'Excluir'"
                          @click="ModalConfirmacao(item)"
                        >
                          <v-icon color="red" class="mr-2 icon-menu">
                            mdi-delete
                          </v-icon>
                          {{ opcao.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <!-- Campo cod_imovel -->
                <td style="width: 5%">
                  {{ item.cod_imovel }}
                </td>
                

                <!-- Campo nome_imovel -->
                <td style="width: 25%">
                  <router-link
                    :to="'/imovelcontainer/' + item.cod_imovel"
                    class="nav-link d-flex flex-row align-center my-2"
                    aria-current="page"
                    style="width: 110%; white-space: normal"
                  >

                    <!-- Avatar do usuário -->
                    <v-avatar
                      rounded
                      size="50px"
                      class="mr-2"
                      color="#d0d0d0"
                    >
                      <span v-if="!item.imagem_capa_caminho">
                        {{ nomeIniciais(item.proprietario) }}
                      </span>
                      <v-img
                        v-if="item.imagem_capa_caminho"
                        :src="baseURL + item.imagem_capa_caminho"
                        alt="avatar"
                      />
                    </v-avatar>

                    <!-- Informações do Imóvel alinhadas à direita -->
                    <div class="d-flex flex-column justify-center text-left" style="flex-grow: 1;">
                      <span
                        style="font-size: 14px; font-weight: bold"
                        :style="{ color: COR_SUBTITULO }"
                      >
                        {{ item.nome_imovel }}
                      </span>
                      <span
                        style="font-size: 12px; color: #808080 !important"
                      >
                      <span style="font-weight: bold">Tipo: </span>
                        {{ item.imovel_tipo.nome }}
                      </span>
                      <span
                        style="font-size: 12px; color: #808080 !important"
                      >
                      <span style="font-weight: bold">Proprietário: </span>
                        {{ item.empreend_nome }}
                      </span>

                      <span
                        style="font-size: 12px; color: #808080 !important"
                      >
                       <span style="font-weight: bold ">Quadra: </span>
                        {{ item.quadra }}
                        
                        <span style="font-weight: bold" class="ml-4">Lote: </span>
                        {{ item.lote }}
                      </span>
                   
                    </div>
                  </router-link>
                </td>

                <!-- Campo Área -->
                <td
                  style="width: 20%; font-size: 12px; color: #808080 !important"
                >
                    <span
                      class="d-flex align-center"
                      style="font-size: 12px; color: #808080 !important"
                    >
                    </span>
                  <div>
                    <span
                      style="font-weight: bold" class="mr-1"
                    >
                      <span> Área Terreno: </span>
                    </span>
                      {{ item.area_metro > 1 ? item.area_metro + "m²" : '' }}
                  </div>
                    <span
                      style="font-weight: bold" class="mr-1"
                    >
                      <span >Área Construida: </span>
                    </span>
                    {{ item.area_construida > 1 ? item.area_construida + 'm²' : '' }}
                </td>

                <!-- Data Matricula -->
                <td
                  style="width: 15%; font-size: 12px; color: #808080 !important"
                >
                    <span
                      class="d-flex align-center"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-1">Matricula:</span>
                      {{ item.matricula }}
                    </span>
                    <span
                     class="d-flex align-center"
                     stle="font-size: 12px; color: #808080 !important"
                    >
                      <span style="font-weight: bold" class="mr-1">Data:</span>
                      {{ item.dt_matricula ? formatDate(item.dt_matricula) : '' }}
                    </span>
                </td>

                 <!-- Campo Situação -->
                <td style="width: 15%; font-size: 12px; color: #808080 !important">
                  <!-- Disponível -->
                  <div>
                    <span class="chip_style" :style="{'background-color':item.imovel_situacao.situacao_venda_cor_html, 'color':item.imovel_situacao.situacao_venda_cor_html_fonte}">
                      {{ item.imovel_situacao.situacao_nome }}
                    </span>
                  </div>

                </td>

                 <!-- Campo valor-->
                <td style="width: 18%; font-size: 12px; color: #808080 !important">
                  <div>
                    <span
                      class="d-flex align-ce  nter"
                      style="font-size: 12px; color: #808080 !important"
                    >
                      {{
                        item.preco_total
                          ? formatNumber(item.preco_total)
                          : ""
                      }}
                    </span>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-col>
      <!-- Fim Grade --------------------------------- -->
    </v-row>

    <!-- dialog -->

    <!-- Paginacao -->
    <div
      class="paginacao d-flex justify-end align-center"
      style="background-color: #ffffff"
    >
      <span style="color: grey; font-size: 14px" class="mr-3"
        >{{ store_Imovel.count_dados }} linhas</span
      >
      <v-pagination
        id="pagination"
        ref="pagination"
        v-model="currentPage"
        :length="store_Imovel.paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
        class="mt-n1"
        style="color: grey"
      />
    </div>

    <!-- Dialog Confirmação de Exclusão -->
    <v-container>
      <v-dialog
        v-model="dialogCancelar"
        transition="dialog-bottom-transition"
        max-width="410"
        class="pa-0"
      >
        <v-card elevation="0" class="">
          <v-card-title
            v-bind:style="{ 'background-color': COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3"
          >
            <span class="text-white title-page body-1"></span>
            <v-btn @click="dialogCancelar = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-title class="text-h6 custom-card-title">
            <p>Deseja realmente excluir esse Imóvel?</p>
            <p>
              Atenção: Todos os dados serão permanentemente apagados. Essa ação
              não pode ser desfeita.
            </p>
            <p>Por favor, confirme se deseja prosseguir.</p>
          </v-card-title>
          <v-card-text>
            <v-container class="mt-0 px-0">
              <v-row>
                <v-col class="pb-0" cols="12">
                  <v-form ref="form" v-model="valid">
                    <v-textarea
                      rows="1"
                      auto-grow
                      counter="200"
                      v-model="container.message"
                      :rules="[rules.required]"
                      class="input-razao mb-0"
                      loader-height="1"
                      autofocus
                      background-color="#FFF"
                      label="Motivo da exclusão:"
                      placeholder="Motivo da exclusão:"
                      light
                      filled
                      outlined
                      required
                      clearable
                      dense
                    ></v-textarea>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn
              id="btn_cancelar"
              ref="btn_cancelar"
              class="mr-4"
              color="primary"
              text
              @click="dialogCancelar = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :disabled="!valid"
              :loading="loading"
              @click="deletaImovel()"
            >
              Confirmar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import store_usuario from "../../../store/store_usuario";
import store_Imovel from "./store_Imovel";
import { API, baseURL } from "../../../services/API";
import VuetifyMoney from "@/components/ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import store_site from "./../../../store/store_site";
import axios from "axios";
import VueCropImage from "../../ComponentesGlobais/CropImage/VueCropImage.vue";
import DatePicker from "../../ComponentesGlobais/Date-Picker/date-picker.vue";
import {
  nomeIniciais,
  formatNumber,
  formatDate,
} from "../../../services/funcoes";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO,
  COR_SECUNDARIA_2,
  MASK_CNO,
  
} from "../../../services/constantes";


export default {
  name: "ImovelConsulta",
  components: {
    DatePicker,
    VuetifyMoney,
  },

  data() {
    return {
      store_usuario: store_usuario,
      store_Imovel: store_Imovel,
      formatDate: formatDate,
      formatNumber: formatNumber,
      MASK_CNO: MASK_CNO,

      loading: false,
      search: null,
      valid: false,
      search_input_focus: false,
      menu: false,
      array_imovel: null,
      loading_excluir: false,
      filtro: null,
      currentPage: 1,
      arrayTipo: null,
      nomeIniciais    : nomeIniciais,
      baseURL         : baseURL,
      empreendimentos : [],

      /* Container  */
      container: {
        //receipt: "",
        message: "",
      },

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SECUNDARIA: COR_SECUNDARIA,
      COR_SECUNDARIA_2: COR_SECUNDARIA_2,
      COR_SUBTITULO: COR_SUBTITULO,
      dialogCancelar: false,

      //situacao: ["Finalizada", "Em Andamento", "Parada"],

      filtro_local: {
        // Filtro na Tela de Consulta --------
        cod_situacao: null,
        cod_imovel: null,
        nome_imovel: null,
        cod_imoveltipo: null,
        situacao: null,
        tipo: null,
        matricula: null,
        valor: null,
        proprietario: null,
        cartorio: null,
        cod_empreendimento: null,
        empreendimento: null,
        area_construida: null,
        area_construida_max: null,
        area_construida_min: null,
        area_metro: null,
        area_metragem_max: null,
        area_metragem_min: null,
        dt_matricula : null,
        fase : null,
        // endereco : null,
        // Filtro Avancado --------------------
      },

      data_picker: {
        "min-width": "150px !important",
      },

      rules: {
        required: (v) => !!v || "Preencha o motivo para continuar",
      },

      /* Cabeçalho da Tabela */
      headers: [
        { text: "", value: "", sortable: false },
        { text: "Código", value: "cod_imovel", sortable: true },
        { text: "Imovel", value: "nome_imovel", sortable: true },
        { text: "Área", value: "area", sortable: true },
        { text: "Matricula", value: "num_matricula", sortable: true },
        { text: "Situação", value: "situacao", sortable: true },
        { text: "Valor", value: "valor", sortable: true },
      ],

      arrayCaracEspecial: [
        "Academia", "Acess Defic.", "Aclive", "Água Nascente", "Área de lazer", "Área Rural", "Carpete", "Casa Caseiro", "Câmara Frigori.", 
        "Churrasqueira", "Closet" , "Condomínio", "Cobertura", "Curral", "Declive", "Despensa", "Duplex", "Edícula", "Energia Elétrica",
        "Esquina", "Flat", "Galpão", "Horta", "Irrigação", "Isolada", "Isol. Acústico", "Isol Térmico", "Jardim", "Lareira", "Loft", "Mezanino",
        "Murado", "Painel Solar", "Paletização", "Pasto", "Patio Externo", "Pavimentação", "Piso Cêramica", "Piso Flutuante", "Piso Granito",
        "Piso Industrial", "Piso Laminado", "Piso Madeira", "Piso Mármore", "Piso Porcelana", "Piso Vinilíco", "Playground", "Portão Eletro.",
        "Portaria", "Pomar", "Quadra Esportiva", "Quintal", "Recepção", "Rede Água", "Rede Esgoto", "Represa", "Sacada", "Sala Reunião", 
        "Sala de Festas", "Sala de Jogos", "Sauna", "Serv. Limpeza", "Sist. Segurança", "Sist. Incêndio", "Sobrado", "Triplex", "Varanda", 
        "Vila", "Vitrine", "Zeladoria", "Zona Industrial"
      ],

      arraySituacao: [],

      // arraySituacao: [
      //   "Disponivel", //Verde
      //   "Vendido", // Primary
      //   "Alugado", // Primary
      //   "Reservado", //Amarelo
      //   "Inativo", //Vermelho
      //   "Em Reforma", //Laranja
      //   "Ocupado", //Amarelo
      //   "Em Litigio", //Amarelo
      //   "Desativado", //Vermelho
      //   "Em Construção", //Amarelo
      //   "Em Projeto", //Amarelo
      //   "Em Negociação", //Amarelo
      //   "Cancelado", //Vermelho
      //   "Suspenso", //Vermelho
      //   "Em Manutenção", //Amarelo
      //   "Em Renovação", //Verde
      // ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],
    };
  },

  async created() {
     this.store_Imovel.acao = "C";
    },

  async mounted() {
    this.empreendimento_privilegio_acesso()
    await this.getSituacoes();
    await this.busca(1);
    console.log("Dados do get:",this.store_Imovel.dados)
    await this.imovelTipo();
    this.store_Imovel.imovel_edicao = {};
  },

  watch: {
    search(val) {
      // console.log('WATCH', val)
      this.currentPage = 1;
    },
  },

  computed: {
    ImagemCapaCaminhoCompleto: function (item) {
      return baseURL + item.imagem_capa_caminho;
    },

    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    tableHeight() {
     if (this.isMobile)
        return window.innerHeight - 149 + 30 - 90;
      else
        return window.innerHeight - 149 - 85;
    }
  },

  methods: {    
    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },
    
    async getSituacoes() {
      const resp = await API.get(`situacoes`);
      const { data } = resp;
      this.arraySituacao = [];
      if (resp.status == 200) {
        this.arraySituacao = data.result;
        console.log('=========', this.arraySituacao);
      }
    },

    ModalConfirmacao(item) {
      this.store_Imovel.imovel_selecionado = item;
      this.dialogCancelar = true;
    },
    async deletaImovel() {
      var lo_params = { cod_imovel: this.store_Imovel.imovel_selecionado.cod_imovel };
      const lo_Res = await API.delete(`imovel/${this.store_Imovel.imovel_selecionado.cod_imovel}`, lo_params );
      this.dialogCancelar = false;
      this.busca();
      return lo_Res;
    },

    handlePageChange(value) {
      console.log('11111111111')      
      this.currentPage = value;
      this.busca(value);      
    },

    limpa_todos() {
      for (let prob in this.filtro_local) {
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null;
      }
      this.filtro_local.valor_max = ``;
      this.store_Imovel.filtro = {};
    },

    barra_porcentagem(index) {
      const valorRealizado = this.store_Imovel.dados[index].valor;
      const valorPrevisto = this.store_Imovel.dados[index].valor;
      let porcentagem = Math.round((valorRealizado / valorPrevisto) * 100, 2);
      return porcentagem;
    },

    async busca(page) {
      this.loading = true;
      this.menu = false;
      this.array_imovel = [];
      //Opções de busca do Filtro
      // Construida Minima
      this.filtro_local.area_construida_min
        ? (this.filtro_local.area_construida_min = Number(
            this.filtro_local.area_construida_min
          ))
        : null;
        // Construida Maxima
      this.filtro_local.area_construida_max
        ? (this.filtro_local.area_construida_max = Number(
            this.filtro_local.area_construida_max
          ))
        : null;
        // Metragem minima
      this.filtro_local.area_metragem_min
        ? (this.filtro_local.area_metragem_min = Number(
            this.filtro_local.area_metragem_min
          ))
        : null;
        // Metragem maxima
      this.filtro_local.area_metragem_max
        ? (this.filtro_local.area_metragem_max = Number(
            this.filtro_local.area_metragem_max
          ))
        : null;
      
      // Filtro do Tipo
      if (this.filtro_local.tipo && this.filtro_local.tipo.cod_imoveltipo) {
          this.filtro_local.cod_imoveltipo = this.filtro_local.tipo.cod_imoveltipo;
          console.log('Tipo válido', this.filtro_local.cod_imoveltipo);
      }
      // Filtro Empreendimento
      if (this.filtro_local.empreendimento && this.filtro_local.empreendimento.cod_empreendimento) {
        this.filtro_local.cod_empreendimento = this.filtro_local.empreendimento.cod_empreendimento;
      }
      // Filtro Situação
      if (this.filtro_local.situacao) {
        this.filtro_local.cod_situacao = this.filtro_local.situacao.cod_situacao;
      }
      // Filtro Nome
      if (this.filtro_local.nome_imovel) {
        this.filtro_local.nome_imovel = this.filtro_local.nome_imovel; 
      }
      // Filtro num Matricula
      if (this.filtro_local.matricula) {
        this.filtro_local.matricula = this.filtro_local.matricula
      }
      // Filtro Situação
      if (this.filtro_local.cartorio) {
        this.filtro_local.cartorio = this.filtro_local.cartorio
      }
      //Filtro de data matricula
      if (this.filtro_local.dt_matricula){
        this.filtro_local.dt_matricula = this.filtro_local.dt_matricula
      }
      //Filtro de endereço
      // if (this.filtro_local.endereco) {
      //   this.filtro_local.endereco = this.filtro_local.endereco
      // }
      // Filtro de Fase
      if (this.filtro_local.fase) {
        this.filtro_local.fase = this.filtro_local.fase
      }

      // Seta o Filtro do Container
      this.store_Imovel.filtro = { ...this.filtro_local };

      // Seta o parametro que vai ser enviado ao Back
      var lo_params = { ...this.store_Imovel.filtro };
      page ? lo_params.page = page : null;

      // Retirando esta estrutura quando for enviar ao Back, pois o banck tem de
      // receber exatamente os campos que existem no model
      delete lo_params["situacao"]
      delete lo_params["tipo"]
      delete lo_params["empreendimento"]

      console.log('Filtro: ', lo_params)

      // console.log("Parâmetros de busca: ", lo_params)
      await this.store_Imovel.ImoveisGet(lo_params);
      this.loading = false;

      let response = await this.store_Imovel.ImoveisGet(lo_params);
      this.loading = false;
    },

    async imovelTipo() {
      const ls_Rel_Tipo = await API.get(`/imovel/tipo`);
      this.arrayTipo = ls_Rel_Tipo.data.result;
    },

    abriuConsulta(val) {
      // console.log( 'abriuConsulta', this.dados_editar)
      console.log("abriuConsulta", this.filtro_local);
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = { ...this.store_Imovel.filtro };
        });
      }
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
    
   

  },
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.icon-filter {
  font-size: 20px;
}

.false-filter {
  background-color: #6c87a2;
  height: 38px !important;
  min-width: 400px !important;
  /* max-width: 900px!important; */
  border-radius: 5px;
  justify-content: space-between;
}

.false-text {
  width: calc(100%);
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #C9CBCA;
  cursor: pointer;
  overflow-x: hidden;
}

table tr {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.status_ativo {
  font-size: 11px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 10px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 11px !important;
  background: goldenrod;
  color: #fff;
  padding: 1px 10px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 11px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 10px;
  border-radius: 25px;
  height: 24px;
}

.width-adress {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

.customer {
  display: block;
  font-weight: 600;
}

.email {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
  opacity: 0.8;
}

.custom-card-title p {
  word-break: break-word; /* Evita que palavras sejam quebradas de forma estranha */
  white-space: normal; /* Garante que o texto quebre linhas naturalmente */
}

.v-btn {
  display: inline-block;
}

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.search-input,
.search-input-longo {
  min-width: 400px;
  max-width: 400px;
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

@media (max-width: 1024px) {
  .nav-link {
    padding-right: 0px !important;
  }
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #fff !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  } 

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
  }

  .container-principal {
    max-width: 100% !important;
  }
}

.v-menu__content {
  z-index: 10 !important;
}

.cor-subtitulo {
  color: var(--COR_SUBTITULO);
}

/* Chips Situação */
.chip_style {
  font-size: 11px !important;
  color: #fff;
  padding: 2px 10px;
  border-radius: 25px;
  height: 24px;
}

</style>
